@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url("reset.css");

body {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 300 !important;
  letter-spacing: -0.01em !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: -0.01em !important;
}

/* form-group */
.form-group {
  margin-bottom: 0.7em;
  /* border: 1px solid red; */
}
.form-group * {
  box-sizing: border-box;
}

.form-group :nth-child(1) {
  display: block;
  font-weight: 500;
  color: #444;
  font-size: 0.8em;
  margin-bottom: 0.3em;
}

.form-group :nth-child(3) {
  margin-top: 0.3em;
  font-size: 0.8em;
  color: red;
  font-weight: 400;
}

.errorMessage {
  margin-top: 0.3em;
  font-size: 0.8em;
  color: red;
  font-weight: 400;
}

.form-group input[type="text"],
input[type="number"],
input[type="password"],
textarea {
  width: 100%;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  color: #666;
}

.form-group textarea {
  height: 60px;
}

.form-group input::placeholder {
  color: #999;
  font-style: italic;
}
textarea::placeholder {
  color: #999;
  font-style: italic;
}

.form-group.required label:after {
  content: " *";
  color: red;
}

/* flex-box */
form .flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

form .flex div {
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
}

form .flex div:first-child {
  margin-left: 0;
}

form .flex div:last-child {
  margin-right: 0;
}

input:read-only,
textarea:read-only {
  background-color: #f8f8f8;
}

.btn {
  background: #4caf50 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-group {
  display: flex;
  justify-content: flex-end;
}

.btn:hover {
  background-color: #45a049 !important;
}

/* table */
.table-group {
  text-align: center;
  overflow: auto;
}
.table-group table {
  border-top: 2px solid #666;
  width: 100%;
  border-collapse: collapse;
}
.table-group th {
  position: sticky;
  top: 0px;
  height: 42px;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table-group th.price {
  width: 85px;
}

.table-group th span {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}
.table-group table td {
  height: 38px !important;
  line-height: 38px;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table-group table td.right {
  text-align: right;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding-right: 5px;
}

.table-group table td.result {
  /* background-color: yellow; */
}

.table-group table tr:nth-child(even) {
  background-color: #f7f9fc;
}

.full-width {
  width: 100% !important;
}
